// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from "jquery";
window.jQuery = $;
window.$ = $;

import tippy, {roundArrow} from 'tippy.js';
import select2 from 'select2';
import datepicker from 'js-datepicker'
import "../vendor/jquery.ba-dotimeout.min.js";

import moment from "moment";
window.moment = moment;

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery");
require("@fancyapps/fancybox")

// import { Util } from "components/util";

require("components/_1_anim-menu-btn.js");
require("components/_2_flexi-header.js");
require("components/_1_alert-card.js");
require("components/_1_flash-message.js");
require("components/_1_alert.js");
require("components/_1_custom-select.js");
require("components/_1_choice-buttons.js");
require("components/_1_file-upload.js");


$(document).on("ready turbolinks:load", function () {

  // To prevent mobile menu from blinking
  $(document).on("click", "#mobile-menu a", function (e) {
    parent.jQuery.fancybox.getInstance().close();
  });

  $('.datepicker').each(function(index, el) {
    console.log($(el).val())
    if ($(el).val() != undefined && $(el).val() != "") {
      var defaultDate = moment($(el).val(), 'YYYY-MM-DD').toDate();
    } else {
      var defaultDate = new Date()
    }
    const picker = datepicker(el, {
      customDays: ["D", "L", "M", "M", "J", "V", "S"],
      customMonths: ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'],
      dateSelected: defaultDate,
      startDay: 1,
      formatter: (input, date, instance) => {
        const value = date.toLocaleDateString('ro-RO')
        input.value = value // => '1/1/2099'
      }
    })
  });


  $('[data-if][data-is]').each(function(index, el) {
    var dataIF = $(el).data('if')
    console.log(dataIF);
    var dataIS = $(el).data('is')
    console.log(eval(dataIS));
    $(dataIF).on('change', function(){
      updateIFISVisibility()
    })
    updateIFISVisibility()
    function updateIFISVisibility() {
      if (eval(dataIS).includes($(dataIF).val())) {
        $(el).removeClass('hide')
      } else {
        $(el).addClass('hide')
      }
    }
  });

  initJS()

  $(document).on('change', '[data-put-checkbox]', function(e){
    if ($(this).prop('checked')) {
      var url = $(this).attr('data-put-checkbox-true')
    } else {
      var url = $(this).attr('data-put-checkbox-false')
    }
    $.ajax({
      url: url,
      type: 'PUT',
    })
  })


  //parse links
  $(".parse-links").each(function (index, el) {
    var str = $(el).html();
    var regexp = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi;
    var matches_array = str.match(regexp);
    $.each(matches_array, function (index, url) {
      var default_protocol = "http://";
      if (str.toLowerCase().indexOf("http") >= 0) {
        default_protocol = "";
      }
      str = str.replace(
        url,
        '<a target="_blank" href="' + default_protocol + url + '">' + url + "</a>"
      );
    });
    //console.log(matches_array);
    //console.log(str);
    $(el).html(str);
  });


});


$(document).on('turbolinks:before-cache', function() {
  $('.multiselect select').select2('destroy');
})

window.updatePartials = updatePartials
function updatePartials() {
  $('[data-update-url]').each(function(index, el) {
    var elID = $(el).attr('id')
    var url = $(el).attr('data-update-url')
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'html',
    })
    .done(function(html) {
      console.log("success");
      $('#'+elID).html($(html).find("#"+elID).html())
    })
    .fail(function() {
      console.log("error");
    })
  });
}

window.initJS = initJS
function initJS() {
  $('.multiselect select').select2();

  tippy('[data-tippy-content]', {
    animation: 'shift-away-subtle',
    arrow: roundArrow,
    allowHTML: true,
  });
}