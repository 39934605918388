// File#: _1_alert-card
// Usage: codyhouse.co/license
import { Util } from "components/util";
document.addEventListener("turbolinks:load", function () {
  function initAlertCard(card) {
    card.addEventListener("click", function (event) {
      if (event.target.closest(".js-alert-card__close-btn"))
        Util.addClass(card, "is-hidden");
    });
  }

  var alertCards = document.getElementsByClassName("js-alert-card");
  if (alertCards.length > 0) {
    for (var i = 0; i < alertCards.length; i++) {
      (function (i) {
        initAlertCard(alertCards[i]);
      })(i);
    }
  }
});
